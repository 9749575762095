<!--
    Created by 程雨喵'mac on 2024/10/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：提交运营模版(新增+复制)
-->
<style lang="less">
</style>

<template>
  <yg-modal
    :title="model.templateId ? '编辑模板' : '新增模板'"
    width="600"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="padding-primary-bottom"
    >
      <v-detail
        ref="templateDetail"
        :is-edit="true"
        :model="model"
        @on-change="onChangeDetail"
      />
    </div>
  </yg-modal>
</template>

<script>
import OperationsApiSet from '@/request/api/OperationsApiSet';
import vDetail from './detail.vue';
export default {
  components: { vDetail },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      title: '新增模板',
      buttonDisabled: true,
      buttonDisabledMsg: ''
    };
  },
  computed: {
    isEdit () {
      return this.model.templateId;
    }
  },
  created () {},
  methods: {
    onSure () {
      const { success, msg, params } = this.$refs.templateDetail.$checkSubmitDataValid();
      if (success) {
        params.name = this.model.name;
        if (this.isEdit) {
          this.requestDel(this.model.templateId, params);
        } else {
          // 先删-再加
          this.requestAdd(params);
        }
      } else if (msg) {
        this.$Message.warning(msg);
      }
    },
    onClose () {
      this.$emit('on-close', false);
    },
    onChangeDetail ({ disabled, msg }) {
      this.buttonDisabled = disabled;
      this.buttonDisabledMsg = msg;
    },
    requestAdd (params) {
      const api = OperationsApiSet.addOperationConfig;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$emit('on-close', true);
      });
    },
    // 请求删除
    requestDel (templateId, addParams) {
      const api = OperationsApiSet.deleteOperationConfig;
      api.params = {
        templateId
      };
      api.autoConfig.autoToast = false;
      this.$http(api).then(res => {
        this.requestAdd(addParams);
      }).catch(e => {
        this.$Message.warning('编辑失败，请稍后重试');
      });
    }
  }
};
</script>
